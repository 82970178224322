:root{
  --mobile: 1000px;
}

/*
  1. HTML Elements
  2. Often used classes
  3. Specific classes
*/

/* HTML Elements */


body{
  color: #222;
}


h1, h3{
  margin: 0;
}

a{
  color: #33c481
}

ul{
  margin: 0;
}

li{
  margin: 20px;
}

/* 2. Often used classes */

.container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 700px;
  margin: 0 auto;
}

@media (max-width: 768px){
  .container{
    width: 90vw;
    margin: 0 auto;
  }
}


.buttons__container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.button {
  background: #21a366;
  border-radius: 5px;
  border-style: none;
  box-shadow: rgba(245, 244, 247, .25) 0 1px 1px inset;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 60px;
  line-height: 60px;
  margin-left: -4px;
  outline: 0;
  text-align: center;
  transition: all .3s cubic-bezier(.05, .03, .35, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: bottom;
  width: 190px;
  margin: 20px 40px;
}

.button:hover {
  opacity: .7;
}

.warning{
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  border-radius: 6px;
  background-color: rgb(25, 18, 7);
  display: flex;
  padding: 12px 16px;
  color: rgb(255, 226, 183);
  display: flex;
  align-items: center;
  max-width: 658px;
}

.warning img{
  margin: 0 10px;
  width: 35px;
}

.section__container{
  margin: 0;
  padding: 40px 0;
  margin: auto;
}

.section__container h1{
    color: #222;
    font-size: 38px;
    text-align: center;
}

.section__container h3{
  color: #222;
  font-size: 22px;
  text-align: center;
  margin-top: 25px;
}

.section__container{
  text-align: justify;
}

.section__image{
  border-radius: 5px;
  box-shadow: 0 0 8px #000;
  display: block;
  max-width: 100%;
  height: auto;
}

.codesnippet__pre{
    background-color: #2b3a42;
    border-radius: 3px;
    font-size: 13px;
    line-height: 19px;
    overflow: auto;
    padding: 8px 16px;
    width: 100%;
    padding: 20px;
    box-shadow: 0 0 8px #000;
    box-sizing: border-box;
    margin-top: 50px;
}

.codesnippet__code{
  background: transparent;
  border: none;
  color: #a5cee1;
  margin: 0;
  padding: 0;
  text-shadow: 0 1px 0 rgb(23 31 35 / 50%);
  white-space: pre;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.token{
  border: 0;
  font-size: 100%;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

.string{
  color: #4ab576;
}

.space{
  margin-left: 25px;
}

.space-md{
  margin-left: 50px;
}

.space-lg{
  margin-left: 75px;
}

.comment{
  color:rgba(245, 244, 247, .8);
}

.snippet{
  box-shadow: none;
  background: rgb(240, 240, 240);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  border-radius: 4px;
  width: 100px;
  padding: 2px 10px;
  border: 1px solid rgb(160, 160, 160);
  margin: 5px;
}

.m-0{
  margin: 0 !important;
}


.contributor__image{
  width: 200px;
  border-radius: 6px;
}

.contributors__section{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
}

.contributors__section h1{
  width: 100%;
  text-align: center;
}

.welcome__title{
  font-size: 40px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.title--strong{
  font-size: 42px;
  color:#21a366;
  margin-left: 10px;
}

.welcome__paragraph{
  font-size: 16px;
  line-height: 22px;
}

/* 3. Specific classes */

.contributors__section h1{
  font-size: 38px;
  margin: 50px 0;
}


.App-logo {
  margin: 15px;
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




#getStarted{
  margin-bottom: 35px !important;
}